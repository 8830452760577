import { useState } from "react";

import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { cdnUrl } from "../../../services/config";

import { datetimeService } from "../../../services/datetime.service";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const useTableStyles = makeStyles((theme) => ({
  productTabs: {
    borderBottom: "1px #D3D3D3 solid",
  },
  tableHead: {
    color: theme.palette.primary.main,
  },
  actionIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

const ProductDetailsTabs = ({ product }) => {
  const classes = useTableStyles();
  const [tab, setTab] = useState(0);
  return (
    <>
      <Tabs
        className={classes.productTabs}
        indicatorColor="primary"
        value={tab}
        onChange={(_, v) => setTab(v)}
      >
        <Tab label="description" />
        {product.documentation.length > 0 && <Tab label="documentation" />}
        {product.downloads.length > 0 && <Tab label="downloads" />}
      </Tabs>
      <Box paddingY={4} paddingX={2}>
        <TabPanel value={tab} index={0}>
          <Typography variant="subtitle1">
            {product.productDesc.split("\\n").map((paragraph) => paragraph)}
          </Typography>
        </TabPanel>
        {product.documentation.length > 0 && (
          <TabPanel value={tab} index={1}>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell className={classes.tableHead} width="40%">
                      title
                    </TableCell>
                    <TableCell className={classes.tableHead} width="40%">
                      filename
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      revision
                    </TableCell>
                    <TableCell className={classes.tableHead}>date</TableCell>
                    <TableCell className={classes.tableHead}>actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {product.documentation.map((doc, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <DescriptionOutlinedIcon />
                        </Box>
                      </TableCell>
                      <TableCell>{doc.title}</TableCell>
                      <TableCell>{doc.filename}</TableCell>
                      <TableCell>{doc.revision}</TableCell>
                      <TableCell>{datetimeService.formatDate(new Date(doc.date))}</TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Tooltip title="download">
                            <a
                              target="_blank"
                              href={`${cdnUrl}/static/products/${product.id}/${doc.filename}`}
                            >
                              <IconButton className={classes.actionIcon}>
                                <GetAppOutlinedIcon />
                              </IconButton>
                            </a>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </TabPanel>
        )}
        {product.downloads.length > 0 && (
          <TabPanel value={tab} index={2}>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell className={classes.tableHead} width="40%">
                      title
                    </TableCell>
                    <TableCell className={classes.tableHead} width="40%">
                      filename
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      revision
                    </TableCell>
                    <TableCell className={classes.tableHead}>date</TableCell>
                    <TableCell className={classes.tableHead}>actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {product.downloads.map((file, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <DescriptionOutlinedIcon />
                        </Box>
                      </TableCell>
                      <TableCell>{file.title}</TableCell>
                      <TableCell>{file.filename}</TableCell>
                      <TableCell>{file.revision}</TableCell>
                      <TableCell>{datetimeService.formatDate(new Date(file.date))}</TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Tooltip title="download">
                            <a
                              href={`${cdnUrl}/static/products/${product.id}/${file.filename}`}
                            >
                              <IconButton className={classes.actionIcon}>
                                <GetAppOutlinedIcon />
                              </IconButton>
                            </a>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </TabPanel>
        )}
      </Box>
    </>
  );
};

export default ProductDetailsTabs;
