import React, { useEffect, useState, useCallback } from "react";
import clsx from "clsx";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  FingotiLoading,
  FingotiButton,
  FingotiHeader,
  FingotiMarkdown,
  FingotiModalContent,
  FingotiModalActions,
  FingotiModal,
} from "@fingoti/components";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import { BasketRow } from "./BasketRow";
import { BasketCheckout } from "./BasketCheckout";
import { VATToggle } from "../Shop/Shared/VATToggle";

import { basketService } from "../../services/basket.service";
import { useStoreState } from "../../context/StoreContext";
import { getPrice } from "../Utils/productDataHelpers";
import { stripeKey, accountUrl } from "../../services/config";
import { useProfileState } from "../../context/ProfileContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(6),
  },
  noItemsRow: {
    textAlign: "center",
    height: theme.spacing(32),
    borderBottom: "none",
  },
  tableHead: {
    color: theme.palette.primary.main,
  },
  title: {
    marginTop: theme.spacing(6),
  },
  summary: {
    marginTop: theme.spacing(6),
  },
  textRight: {
    textAlign: "right",
  },
  totals: {
    fontSize: "14pt",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  totalRow: {
    marginTop: theme.spacing(4),
    fontSize: "22pt",
  },
  dividerSpacing: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  basketTotal: {
    color: theme.palette.secondary.main,
  },
  checkoutBtnWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  checkoutBtn: {
    width: "55%",
  },
  actionButtons: {
    "& > :last-child": {
      marginLeft: theme.spacing(2),
    },
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  button: {
    width: "42%",
  },
}));

const stripePromise = loadStripe(stripeKey);

const elFonts = [
  {
    cssSrc: "https://fonts.googleapis.com/css2?family=Work+Sans&display=swap",
    family: "Work Sans",
  },
];

const EmptyBasketConfirm = ({ onConfirm, onCancel }) => {
  const classes = useStyles();

  return (
    <>
      <FingotiModalContent>
        <Box marginY={4} textAlign="center">
          <Typography>Are you sure you want to empty your basket?</Typography>
        </Box>
      </FingotiModalContent>
      <FingotiModalActions>
        <div className={classes.buttonGroup}>
          <FingotiButton
            light
            className={classes.button}
            color="primary"
            onClick={onConfirm}
          >
            Confirm
          </FingotiButton>
          <FingotiButton light className={classes.button} onClick={onCancel}>
            Cancel
          </FingotiButton>
        </div>
      </FingotiModalActions>
    </>
  );
};

export const Basket = () => {
  const classes = useStyles();
  const {
    stock,
    exVat,
    vatExempt,
    pricingTier,
    loading: storeLoading,
    error,
  } = useStoreState();
  const { loading: profileLoading, loggedIn } = useProfileState();
  const [loading, setLoading] = useState(true);
  const [updateBasket, setUpdateBasket] = useState(false);
  const [displayBasket, setDisplayBasket] = useState([]);
  const [basketTotal, setBasketTotal] = useState(0);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [showConfigSelect, setShowConfigSelect] = useState(false);
  const [confirmEmptyOpen, setConfirmEmptyOpen] = useState(false);
  let basketSub;

  const handleStartCheckout = () => {
    if (!loggedIn) {
      window.location.assign(`${accountUrl}?ReturnUrl=${window.location.href}`);
    } else {
      setCheckoutOpen(true);
    }
  };

  const updateQty = (variantId, newQty) => {
    basketService.updateItemQty(variantId, parseInt(newQty));
    setUpdateBasket(!updateBasket);
  };

  const removeItem = (stockId, variantId) => {
    basketService.removeItem(stockId, variantId);
    setUpdateBasket(!updateBasket);
  };

  const emptyBasket = () => {
    basketService.emptyBasket();
    setDisplayBasket([]);
    setBasketTotal(0);
    setConfirmEmptyOpen(false);
    setUpdateBasket(!updateBasket);
  };

  const actionButtons = () => {
    return (
      <div className={classes.actionButtons}>
        <VATToggle display={!vatExempt} />
        <FingotiButton
          light
          color="primary"
          disabled={!basketService.basketValue().length > 0}
          onClick={() => setConfirmEmptyOpen(true)}
        >
          Clear Basket
        </FingotiButton>
      </div>
    );
  };

  const breadcrumbs = [
    { text: "Store", link: "/" },
    { text: "Basket", link: "" },
  ];

  const processBasket = useCallback(
    (basketValue) => {
      if (basketValue.length > 0) {
        let displayBasket = [],
          runningTotal = 0,
          showConfigSelect = false;

        basketValue.forEach((bi) => {
          let product = stock.find((s) => s.id === bi.stockId);
          let variant = product.variants.find((v) => v.id === bi.variantId);
          let price = getPrice(product, variant, pricingTier, exVat, bi.qty);

          let displayItem = {
            product: product,
            variant: variant,
            price: price,
            qty: bi.qty,
          };

          runningTotal += bi.qty * price;
          displayBasket.push(displayItem);

          if (product.category === 0) {
            showConfigSelect = true;
          }
        });

        setBasketTotal(runningTotal);
        setDisplayBasket(displayBasket);
        setShowConfigSelect(showConfigSelect);
        setLoading(false);
      } else {
        setDisplayBasket([]);
        setLoading(false);
      }
    },
    [stock, exVat, pricingTier]
  );

  useEffect(() => {
    if (!profileLoading && !storeLoading && !error) {
      basketSub = basketService.basketObservable.subscribe((basket) => {
        processBasket(basket);
      });

      return () => {
        basketSub.unsubscribe();
      };
    }
  }, [storeLoading, profileLoading, error, updateBasket, processBasket]);

  if (loading) {
    return <FingotiLoading />;
  } else {
    return (
      <Box p={3}>
        <FingotiModal
          title="Empty Basket"
          open={confirmEmptyOpen}
          setOpen={setConfirmEmptyOpen}
        >
          <EmptyBasketConfirm
            onConfirm={emptyBasket}
            onCancel={() => setConfirmEmptyOpen(false)}
          />
        </FingotiModal>
        <FingotiModal
          title="Checkout"
          open={checkoutOpen}
          setOpen={setCheckoutOpen}
        >
          <Elements stripe={stripePromise} options={{ fonts: elFonts }}>
            <BasketCheckout
              showConfigSelect={showConfigSelect}
              onOrderCreated={emptyBasket}
              setOpen={() => setCheckoutOpen(false)}
            />
          </Elements>
        </FingotiModal>
        <FingotiHeader
          breadcrumbs={breadcrumbs}
          sectionIcon={ShoppingCartIcon}
          actionButtons={actionButtons()}
        />
        <FingotiMarkdown path="/documentation/store/basket.md" />
        <Grid container>
          <Grid item xs={12} lg={8}>
            <Typography variant="h5" className={classes.title}>
              Your items
            </Typography>
            <TableContainer>
              <Table className={classes.root}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>
                      part number
                    </TableCell>
                    <TableCell className={classes.tableHead}>item</TableCell>
                    <TableCell className={classes.tableHead}>
                      quantity
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      unit price{" "}
                      {!vatExempt && (exVat ? "(ex VAT)" : "(inc VAT)")}
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      subtotal{" "}
                      {!vatExempt && (exVat ? "(ex VAT)" : "(inc VAT)")}
                    </TableCell>
                    <TableCell className={classes.tableHead}>actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayBasket.length > 0 ? (
                    displayBasket.map((item) => (
                      <BasketRow
                        key={item.variant.id}
                        item={item}
                        setQuantity={updateQty}
                        removeItem={removeItem}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} className={classes.noItemsRow}>
                        You currently don't have any items in your basket
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} lg={1} />
          <Grid item xs={12} lg={3}>
            <Typography variant="h5" className={classes.title}>
              Summary
            </Typography>
            <div className={classes.summary}>
              <Grid container className={classes.totals}>
                <Grid item xs={8} className={classes.textRight}>
                  Subtotal
                </Grid>
                <Grid item xs={4} className={classes.textRight}>
                  £
                  {!exVat
                    ? (basketTotal / 1.2).toFixed(2)
                    : basketTotal.toFixed(2)}
                </Grid>
              </Grid>
              <Grid container className={classes.totals}>
                <Grid item xs={8} className={classes.textRight}>
                  Delivery
                </Grid>
                <Grid item xs={4} className={classes.textRight}>
                  £0.00
                </Grid>
              </Grid>
              {!vatExempt && (
                <Grid container className={classes.totals}>
                  <Grid item xs={8} className={classes.textRight}>
                    VAT (20%)
                  </Grid>
                  <Grid item xs={4} className={classes.textRight}>
                    £
                    {!exVat
                      ? (basketTotal - basketTotal / 1.2).toFixed(2)
                      : (basketTotal * 1.2 - basketTotal).toFixed(2)}
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                className={clsx(classes.totals, classes.totalRow)}
              >
                <Grid item xs={8} className={classes.textRight}>
                  Order Total
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={clsx(classes.textRight, classes.basketTotal)}
                >
                  £
                  {!vatExempt
                    ? !exVat
                      ? basketTotal.toFixed(2)
                      : (basketTotal * 1.2).toFixed(2)
                    : basketTotal.toFixed(2)}
                </Grid>
              </Grid>
              <Divider className={classes.dividerSpacing} />
              <div className={classes.checkoutBtnWrapper}>
                <FingotiButton
                  light
                  color="primary"
                  disabled={!basketService.basketValue().length > 0}
                  onClick={handleStartCheckout}
                  className={classes.checkoutBtn}
                >
                  Proceed to Checkout
                </FingotiButton>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
};
