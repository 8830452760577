import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { FingotiButton } from "@fingoti/components";
import clsx from "clsx";

import {
  makeStyles,
  Grid,
  Card,
  CardActions,
  CardContent,
  Typography,
  CardMedia,
} from "@material-ui/core";

import AddShoppingCartOutlinedIcon from "@material-ui/icons/AddShoppingCartOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DvrOutlinedIcon from "@material-ui/icons/DvrOutlined";

import { basketService } from "../../../services/basket.service";
import { getPrice, getVariant } from "../../Utils/productDataHelpers";

import { DeviceIcon } from "../../../icons/FingotIcons";
import { cdnUrl } from "../../../services/config";
import { StatusEnum } from "./enums";
import { QuantitySelect } from "../../QuantitySelect";
import { BasketButton } from "./BasketButton";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    border: "1px solid" + theme.palette.greyFour.main,
    borderRadius: theme.spacing(1),
  },
  media: {
    minHeight: 210,
    maxHeight: 210,
    marginBottom: theme.spacing(1),
  },
  title: {
    lineHeight: 1,
  },
  content: {
    height: 150,
  },
  actions: {
    height: 40,
    paddingBottom: theme.spacing(2),
  },
  spacing: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  price: {
    fontSize: "14pt",
    color: theme.palette.secondary.main,
  },
  vat: {
    color: theme.palette.greyThree.main,
    marginLeft: theme.spacing(1),
  },
  stock: {
    display: "flex",
    justifyContent: "flex-end",
  },
  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tagline: {
    display: "-webkit-box",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
  },
  button: {
    height: "100%",
  },
  btnTightPad: {
    padding: 0,
  },
  qtySelect: {
    width: "49%",
    marginRight: theme.spacing(1),
  },
  cardContentPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  mediaRibbon: {
    marginTop: "-28px",
  },
  ribbon: {
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
    position: "relative",
    background: "#08769b",
    width: "35%",
    transformOrigin: "top left",
    color: "#fff",
    textAlign: "center",
    transform: "translate(215%) rotate(45deg)",
    boxShadow: "0 0 0 999px #08769b",
    clipPath: "inset(0 -100%)",
    fontWeight: 600,
  },
  discontinued: {
    background: theme.palette.greyTwo.main,
    boxShadow: `0 0 0 999px ${theme.palette.greyTwo.main}`,
  },
  "coming-soon": {
    background: theme.palette.secondary.main,
    boxShadow: `0 0 0 999px ${theme.palette.secondary.main}`,
  },
  new: {
    background: theme.palette.success.main,
    boxShadow: `0 0 0 999px ${theme.palette.success.main}`,
  },
  "on-sale": {
    background: theme.palette.warning.main,
    boxShadow: `0 0 0 999px ${theme.palette.warning.main}`,
  },
  "last-few": {
    background: theme.palette.error.main,
    boxShadow: `0 0 0 999px ${theme.palette.error.main}`,
  },
}));

export const ProductCard = ({ product, exVat, vatExempt, pricingTier }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [variant, setVariant] = useState();
  const [price, setPrice] = useState();
  const [quantity, setQuantity] = useState(product.quantity.increment);
  const [inStock, setInStock] = useState(0);
  const [loading, setLoading] = useState(true);

  const findCorrectVariant = () =>
    new Promise((resolve) => {
      let variant = product.variants.find((v) => v.stock > 0);

      if (!variant) {
        variant = getVariant(product);
      }

      resolve(variant);
    });

  const addToBasket = () => {
    let currentItem = basketService
      .basketValue()
      .find((bi) => bi.stockId === product.id && bi.variantId === variant.id);

    if (currentItem) {
      if (currentItem.qty + parseInt(quantity) > variant.stock) {
        //Tell user can't excced max stock level
        enqueueSnackbar(
          `You cannot request more than the ${variant.stock} avaliable stock of this item`,
          { variant: "error" }
        );
        return;
      }
    }

    basketService.addItem(product.id, variant.id, parseInt(quantity));
    let addedMsg =
      product.productLine !== "Digital"
        ? `Added ${quantity} item${parseInt(quantity) > 1 ? "s" : ""} to basket`
        : "Added to basket";

    enqueueSnackbar(addedMsg, { variant: "success", action: BasketButton });
  };

  const getTotalStock = () => {
    let totalStock = 0;

    product.variants.forEach((v) => {
      totalStock = totalStock + v.stock;
    });

    setInStock(totalStock);
  };

  useEffect(() => {
    setLoading(true);
    findCorrectVariant()
      .then((variant) => {
        setQuantity(variant.stock > 0 ? product.quantity.increment : 0);
        setVariant(variant);
        setPrice(getPrice(product, variant, pricingTier, exVat));
      })
      .finally(() => setLoading(false));
    getTotalStock();
  }, [exVat]);

  const showBanner = !(product.status === 0 || product.status === 1);

  if (loading) {
    return <></>;
  } else {
    return (
      <Card elevation={0} className={classes.root}>
        {showBanner && (
          <div
            className={clsx([
              classes.ribbon,
              classes[
                StatusEnum[product.status].replace(" ", "-").toLowerCase()
              ],
            ])}
          >
            {StatusEnum[product.status].toUpperCase()}
          </div>
        )}
        <CardMedia
          className={clsx(classes.media, { [classes.mediaRibbon]: showBanner })}
          image={`${cdnUrl}/static/products/${product.id}/${product.images[0].filename}`}
          title={product.productName}
        />

        <CardContent
          className={classes.content}
          classes={{
            root: classes.cardContentPadding,
          }}
        >
          <Grid container spacing={1} className={classes.spacing}>
            <Grid item xs={2} className={classes.icon}>
              {product.productLine === "Digital" ? (
                <DvrOutlinedIcon fontSize="large" />
              ) : (
                <DeviceIcon fontSize="large" />
              )}
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5" className={classes.title}>
                {product.productLine} <br />
                <small className={classes.name}>{product.productName}</small>
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.spacing}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                <span className={classes.price}>£{price}</span>
                <small className={classes.vat}>
                  {!vatExempt && (exVat ? "ex VAT" : "inc VAT")}
                </small>
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.stock}>
              <Typography variant="subtitle1">
                {product.productLine === "Digital" ? (
                  <span>&#8734;</span>
                ) : (
                  inStock
                )}{" "}
                in stock
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="subtitle2" className={classes.tagline}>
            {product.productTagline}
          </Typography>
        </CardContent>

        <CardActions
          className={classes.actions}
          classes={{
            root: classes.cardContentPadding,
          }}
        >
          <QuantitySelect
            disabled={variant.stock < 1}
            className={classes.qtySelect}
            quantity={quantity}
            setQuantity={(newQty) => setQuantity(newQty)}
            increment={product.quantity.increment}
            minValue={product.quantity.min}
            maxValue={
              variant.stock < product.quantity.max
                ? variant.stock
                : product.quantity.max
            }
            tight={product.productLine === "Digital"}
          />

          <FingotiButton
            light
            color="primary"
            onClick={() => addToBasket()}
            className={classes.button}
            classes={{
              contained: classes.btnTightPad,
            }}
            disabled={
              variant.stock < 1 ||
              product.status === 0 ||
              product.status === 2 ||
              product.status === 3
            }
          >
            <AddShoppingCartOutlinedIcon />
          </FingotiButton>

          <FingotiButton
            light
            className={classes.button}
            onClick={() => history.push(`/product/${product.id}`)}
          >
            <InfoOutlinedIcon />
          </FingotiButton>
        </CardActions>
      </Card>
    );
  }
};
