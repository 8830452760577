import React, { useEffect, useState, useCallback } from "react";
import { FingotiLoading, FingotiHeader } from "@fingoti/components";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useTheme  from "@material-ui/core/styles/useTheme";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';

import { useStoreState } from "../../../context/StoreContext";

import { CategoryEnum } from "./enums";
import { ProductCard } from "./ProductCard";
import { VATToggle } from "./VATToggle";

//Category ENUMS
//undefined = all
// 0 = gateway (Pebl and Vyne Gateway)
// 1 = sensor
// 2 = power (PSU/PoE/UPS)
// 3 = Accessory (Cables, adapters etc)

export const ProductsGrid = ({ productLine, category: categoryIn }) => {
  const {
    stock,
    exVat,
    vatExempt,
    pricingTier,
    loading: storeLoading,
    error,
  } = useStoreState();
  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(undefined);

  const actionButtons = () => {
    return (
      <React.Fragment>
        <VATToggle display={!vatExempt} />
      </React.Fragment>
    );
  };

  const breadcrumbs = [
    { text: "Store", link: "/" },
    { text: productLine, link: `/${productLine.toLocaleLowerCase()}` },
  ];

  const addCategoryBreadcrumb = (breadcrumbs) => {
    if (category !== undefined) {
      breadcrumbs.push({
        text: CategoryEnum[category].label,
        link: `/${CategoryEnum[category].route}`,
      });
    }

    return breadcrumbs;
  };

  const filterProducts = useCallback(() => {
    //Remove inactive products
    let activeProducts = stock.filter((p) => p.active);

    //Filter by productLine (Pebl or Vyne)
    let lineProducts = stock.filter((p) => p.productLine === productLine);

    let filteredProducts = [];

    //Filter by category
    if (categoryIn !== undefined) {
      if (categoryIn.length > 1) {
        categoryIn.map((c) =>
          filteredProducts.push(...lineProducts.filter((p) => p.category === c))
        );
        setCategory(categoryIn[0]);
        setProducts(filteredProducts);
      } else {
        filteredProducts = lineProducts.filter(
          (p) => p.category === categoryIn
        );
        setCategory(categoryIn);
        setProducts(filteredProducts);
      }
    } else {
      setProducts(lineProducts);
    }

    setLoading(false);
  }, [categoryIn, productLine, stock]);

  useEffect(() => {
    if (!storeLoading && !error) {
      filterProducts();
    }
  }, [storeLoading, error, filterProducts]);

  if (loading) {
    return <FingotiLoading />;
  } else {
    return (
      <React.Fragment>
        <FingotiHeader
          breadcrumbs={addCategoryBreadcrumb(breadcrumbs)}
          sectionIcon={ShoppingCartIcon}
          actionButtons={actionButtons()}
        />

        <Grid container spacing={4}>
          {products.length > 0 ? (
            products.map((product, i) => {
              return (
                <Grid key={i} item xs={12} lg={4} xl={3}>
                  <ProductCard
                    pricingTier={pricingTier}
                    product={product}
                    exVat={exVat}
                    vatExempt={vatExempt}
                  />
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12}>
              <Box
                height="50vh"
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <StorefrontOutlinedIcon style={{ width: "10rem", height: "10rem", color: theme.palette.greyTwo.main }} />
                <Typography style={{ marginTop: 32 }} variant="h3">Coming Soon</Typography>
                <Typography style={{ marginTop: 16 }} variant="h5">We are currently stocking the shelves, please come back soon</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }
};
