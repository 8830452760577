import React, { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import { useHistory, useParams } from "react-router";
import { useSnackbar } from "notistack";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  FingotiHeader,
  FingotiLoading,
  FingotiButton,
  FingotiModal,
} from "@fingoti/components";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import { OrderProgress } from "./OrderProgress";
import { OrderItemRow } from "./OrderItemRow";
import { OrderPay } from "./OrderPay";
import { VATToggle } from "../Shop/Shared/VATToggle";

import { stripeKey } from "../../services/config";
import { apiService } from "../../services/api.service";
import { datetimeService } from "../../services/datetime.service";
import { useProfileState } from "../../context/ProfileContext";
import { useStoreState } from "../../context/StoreContext";
import StoreData from "../../context/StoreData";

const stripePromise = loadStripe(stripeKey);

const elFonts = [
  {
    cssSrc: "https://fonts.googleapis.com/css2?family=Work+Sans&display=swap",
    family: "Work Sans",
  },
];

const useStyles = makeStyles((theme) => ({
  noBottomBorder: {
    border: "none",
  },
  headerCell: {
    border: "none",
    textAlign: "right",
    borderRight: "1px solid " + theme.palette.greyFour.main,
    width: "28%",
    color: theme.palette.greyTwo.main,
  },
  verticalTop: {
    verticalAlign: "top",
  },
  progressBar: {
    width: "80%",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  headerRow: {
    color: theme.palette.primary.main,
  },
  spacing: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  actionButtons: {
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export const OrderDetails = () => {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { setVATExempt } = StoreData();
  const { id } = useParams();
  const { loading: profileLoading } = useProfileState();
  const { exVat, stock, loading: storeLoading } = useStoreState();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderInvoice, setOrderInvoice] = useState(undefined);
  const [orderPayOpen, setOrderPayOpen] = useState(false);
  const [thisVatExempt, setThisVATExempt] = useState(false);

  const actionButtons = () => {
    return (
      <div className={classes.actionButtons}>
        <VATToggle display={!thisVatExempt} />
        {orderInvoice && (
          <>
            {orderInvoice.payment_intent.status !== "succeeded" && (
              <FingotiButton
                light
                color="primary"
                onClick={() => setOrderPayOpen(true)}
              >
                Pay invoice
              </FingotiButton>
            )}
            <FingotiButton
              light
              color="primary"
              onClick={() => window.open(orderInvoice.invoice_pdf)}
            >
              download invoice
            </FingotiButton>
          </>
        )}
      </div>
    );
  };

  const handlePaymentAttempt = () => {
    getOrder();
    setOrderPayOpen(false);
  };

  const processItems = useCallback(
    (orderItems) => {
      let total = 0;
      let displayItems = [];

      orderItems.physical.forEach((pi) => {
        let stockItem = stock.find((s) => s.id === pi.stockId);
        let variant = stockItem.variants.find((v) => v.id === pi.variantId);
        let name =
          variant.invoiceDescription !== ""
            ? `${stockItem.productLine} ${stockItem.productName} - ${variant.invoiceDescription}`
            : `${stockItem.productLine} ${stockItem.productName}`;

        if (stockItem && variant) {
          let displayItem = {
            itemId: variant.id,
            productLine: stockItem.productLine,
            name: name,
            price: pi.itemPrice / 100,
            qty: pi.quantityOrdered,
            lineTotal: (pi.itemPrice / 100) * pi.quantityOrdered,
          };

          displayItems.push(displayItem);
          total += pi.itemPrice * pi.quantityOrdered;
        }
      });

      orderItems.digital.forEach((di) => {
        let stockItem = stock.find((s) => s.id === di.stockId);
        let variant = stockItem.variants.find((v) => v.id === di.variantId);
        let name =
          variant.invoiceDescription !== ""
            ? `${stockItem.productLine} ${stockItem.productName} - ${variant.invoiceDescription}`
            : `${stockItem.productLine} ${stockItem.productName}`;

        if (stockItem && variant) {
          let displayItem = {
            itemId: variant.id,
            productLine: stockItem.productLine,
            name: name,
            key: di.key,
            price: di.itemPrice / 100,
            qty: di.quantity,
            lineTotal: (di.itemPrice / 100) * di.quantity,
          };

          displayItems.push(displayItem);
          total += di.itemPrice * di.quantity;
        }
      });

      setOrderItems(displayItems);
      setOrderTotal(total);
    },
    [stock]
  );

  const getInvoice = useCallback(
    (invId) => {
      return apiService
        .getData(`/billing/invoices/${invId}`)
        .then((result) => {
          setOrderInvoice(result.invoices[0]);
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
        });
    },
    [enqueueSnackbar]
  );

  const getOrder = useCallback(() => {
    setLoading(true);
    apiService
      .getData(`/store/orders/${id}`)
      .then((result) => {
        console.log("order", result);
        setOrder(result);
        setThisVATExempt(result.billingAddress.country !== "GB");
        processItems(result.items);
        if (result.invoiceId) {
          getInvoice(result.invoiceId).then(() => {
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        history.goBack();
        enqueueSnackbar(error, { variant: "error" });
      });
  }, [id, processItems, getInvoice]);

  useEffect(() => {
    if (!profileLoading && !storeLoading) {
      getOrder();
    }

    return () => {
      setVATExempt();
    };
  }, [profileLoading, storeLoading, getOrder]);

  console.log("vat e", thisVatExempt);

  if (loading) {
    return <FingotiLoading />;
  } else {
    const breadcrumbs = [
      { text: "Store", link: "/" },
      { text: "Orders", link: "/orders" },
      {
        text: `${order.orderNumber} ${
          order.customerReference ? `(${order.customerReference})` : ""
        }`,
      },
    ];

    return (
      <Box p={3}>
        <FingotiModal
          title="Pay Invoice"
          setOpen={setOrderPayOpen}
          open={orderPayOpen}
        >
          <Elements stripe={stripePromise} options={{ fonts: elFonts }}>
            <OrderPay
              setOpen={setOrderPayOpen}
              inv={orderInvoice}
              onDone={handlePaymentAttempt}
            />
          </Elements>
        </FingotiModal>
        <FingotiHeader
          breadcrumbs={breadcrumbs}
          sectionIcon={ShoppingCartIcon}
          actionButtons={actionButtons()}
        />
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <Typography variant="h5" className={classes.spacing}>
              Details
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.headerCell}>
                      order number
                    </TableCell>
                    <TableCell className={classes.noBottomBorder}>
                      {order.orderNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.headerCell}>
                      order total
                    </TableCell>
                    <TableCell className={classes.noBottomBorder}>
                      £
                      {!thisVatExempt
                        ? !exVat
                          ? ((orderTotal / 100) * 1.2).toFixed(2) + " (inc VAT)"
                          : (orderTotal / 100).toFixed(2) + " (ex VAT)"
                        : (orderTotal / 100).toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.headerCell}>
                      order date
                    </TableCell>
                    <TableCell className={classes.noBottomBorder}>
                      {datetimeService.formatDateTimeNoSeconds(
                        new Date(order.orderedDate)
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className={clsx(classes.headerCell, classes.verticalTop)}
                    >
                      billing address
                    </TableCell>
                    <TableCell className={classes.noBottomBorder}>
                      {order.billingAddress.line1}
                      <br />
                      {order.billingAddress.line2 ? (
                        <>
                          {order.billingAddress.line2} <br />
                        </>
                      ) : (
                        ""
                      )}
                      {order.billingAddress.city}
                      <br />
                      {order.billingAddress.county}
                      <br />
                      {order.billingAddress.postcode}
                      <br />
                      {order.billingAddress.country}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className={clsx(classes.headerCell, classes.verticalTop)}
                    >
                      delivery address
                    </TableCell>
                    <TableCell className={classes.noBottomBorder}>
                      {order.deliveryAddress.line1}
                      <br />
                      {order.deliveryAddress.line2 ? (
                        <>
                          {order.deliveryAddress.line2} <br />
                        </>
                      ) : (
                        ""
                      )}
                      {order.deliveryAddress.city}
                      <br />
                      {order.deliveryAddress.county}
                      <br />
                      {order.deliveryAddress.postcode}
                      <br />
                      {order.deliveryAddress.country}
                    </TableCell>
                  </TableRow>
                  {order.preset && (
                    <TableRow>
                      <TableCell className={classes.headerCell}>
                        preset number
                      </TableCell>
                      <TableCell className={classes.noBottomBorder}>
                        {order.preset.presetNumber} - {order.preset.presetName}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    {orderInvoice && (
                      <>
                        <TableCell className={classes.headerCell}>
                          invoice number
                        </TableCell>
                        <TableCell className={classes.noBottomBorder}>
                          {order.invoiceNumber}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography variant="h5" className={classes.spacing}>
              Progress
            </Typography>
            <OrderProgress
              className={classes.progressBar}
              status={order.orderStatus}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.spacing}>
              Items
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerRow}>
                      part number
                    </TableCell>
                    <TableCell className={classes.headerRow}>item</TableCell>
                    <TableCell className={classes.headerRow} align="center">
                      quantity
                    </TableCell>
                    <TableCell className={classes.headerRow}>
                      unit price{" "}
                      {!thisVatExempt && (exVat ? "(ex VAT)" : "(inc VAT)")}
                    </TableCell>
                    <TableCell className={classes.headerRow}>
                      line total{" "}
                      {!thisVatExempt && (exVat ? "(ex VAT)" : "(inc VAT)")}
                    </TableCell>
                    <TableCell className={classes.headerRow}>actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderItems.map((item) => (
                    <OrderItemRow key={item.itemId} item={item} exVat={exVat} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }
};
