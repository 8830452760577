import React from "react";
import { useHistory } from "react-router";
import { TableRow, TableCell, makeStyles, Tooltip } from "@material-ui/core";
import { FingotiDelete } from "@fingoti/components";

import { DeviceIcon } from "../../icons/FingotIcons";

import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DvrOutlinedIcon from "@material-ui/icons/DvrOutlined";

import { QuantitySelect } from "../QuantitySelect";

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    display: "flex",
    alignItems: "center",
    "& > :first-child": {
      marginRight: theme.spacing(2),
    },
  },
  quantityInput: {
    width: "15%",
  },
  actionIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

export const BasketRow = ({ item, setQuantity, removeItem }) => {
  const history = useHistory();
  const classes = useStyles();
  const { product, variant, price, qty } = item;

  return (
    <TableRow>
      <TableCell>{variant.id}</TableCell>
      <TableCell>
        <div className={classes.itemWrapper}>
          {product.productLine === "Digital" ? (
            <DvrOutlinedIcon fontSize="large" />
          ) : (
            <DeviceIcon fontSize="large" />
          )}
          {product.productLine} {product.productName}{" "}
          {variant.invoiceDescription}
        </div>
      </TableCell>
      <TableCell className={classes.quantityInput}>
        <QuantitySelect
          quantity={qty}
          setQuantity={(newQty) => setQuantity(variant.id, newQty)}
          increment={product.quantity.increment}
          minValue={product.quantity.min}
          maxValue={
            variant.stock < product.quantity.max
              ? variant.stock
              : product.quantity.max
          }
        />
      </TableCell>
      <TableCell>£{price}</TableCell>
      <TableCell>£{(price * qty).toFixed(2)}</TableCell>
      <TableCell>
        <Tooltip title="item details">
          <IconButton
            className={classes.actionIcon}
            onClick={() => history.push(`/product/${variant.id}`)}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
        <FingotiDelete
          remove
          className={classes.actionIcon}
          tooltipText="remove item"
          onClick={() => removeItem(product.id, variant.id)}
        />
      </TableCell>
    </TableRow>
  );
};
