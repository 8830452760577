import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useCookies } from "react-cookie";
import { FingotiNotFound, FingotiLoading } from "@fingoti/components";

import Layout from "./components/Layout";
import { Shop } from "./components/Shop/Shop";
import { Basket } from "./components/Basket/Basket";
import { VyneProducts } from "./components/Shop/VyneProducts";
import { ProductDetails } from "./components/Shop/Shared/ProductDetails";
import { Orders } from "./components/Orders/Orders";
import { OrderDetails } from "./components/Orders/OrderDetails";

import { accountUrl } from "./services/config";
import { authService } from "./services/auth.service";
import { useProfileDispatch, useProfileState } from "./context/ProfileContext";
import { ProfileData } from "./context/ProfileData";
import StoreData from "./context/StoreData";

import { PeblProducts } from "./components/Shop/PeblProducts";
import { DigitalProducts } from "./components/Shop/DigitalProducts";
import { useStoreDispatch, useStoreState } from "./context/StoreContext";

const PrivateRoute = ({ children, ...rest }) => {
  const { loggedIn, loading } = useProfileState();

  //not logged in so redirect to login page with the return url
  if (!loggedIn & !loading) {
    console.log("no user");
    window.location.assign(`${accountUrl}?ReturnUrl=${window.location}`);
  }

  if (loggedIn & !loading) {
    // authorised so return component
    return <Route {...rest}>{children}</Route>;
  }

  return <FingotiLoading fullHeight />;
};

const App = () => {
  const storeData = StoreData();
  const profileData = ProfileData();
  const profileDispatch = useProfileDispatch();
  const storeDispatch = useStoreDispatch();
  const {
    loading: profileLoading,
    organisation,
    addresses,
  } = useProfileState();
  const [cookies, setCookie] = useCookies();

  const handleVisibilityChange = (e) => {
    if (document.visibilityState === "visible") {
      console.log("page is vis, checking cookie and LS match");
      authService.findAOCookie().then((aoCookie) => {
        authService.findAppToken().then((appToken) => {
          if (aoCookie !== appToken.oid) {
            authService.getAppToken().then(() => {
              profileData.getData();
            });
          }
        });
      });
    }
  };

  document.addEventListener("visibilitychange", handleVisibilityChange, false);

  useEffect(() => {
    storeData.fillStoreContext();
    console.log("Cookies");
    console.log(cookies.ActiveOrganisation);
    if (cookies.ActiveOrganisation) {
      authService
        .findAppToken()
        .then((appToken) => {
          let hasExpired =
            Date.now() / 1000 > new Date(appToken.exp) ? true : false;
          if (!hasExpired) {
            if (cookies.ActiveOrganisation !== appToken.oid) {
              authService.getAppToken().then(() => {
                profileData.getData();
              });
            } else {
              profileDispatch({ type: "LOGGED_IN" });
              profileData.getData();
            }
          } else {
            authService
              .getAppToken()
              .then(() => {
                profileData.getData();
              })
              .catch(() => {
                profileDispatch({ type: "LOADED" });
              });
          }
        })
        .catch(() => {
          authService
            .getAppToken()
            .then(() => {
              profileData.getData();
            })
            .catch(() => {
              profileDispatch({ type: "LOADED" });
            });
        });
    } else {
      profileDispatch({ type: "LOADED" });
    }
  }, []);

  return (
    <div className="App">
      <Layout>
        <Switch>
          <Route exact path="/" component={Shop} />

          <Route exact path="/vyne" component={VyneProducts} />
          <Route path="/vyne/:cate" component={VyneProducts} />

          <Route exact path="/pebl" component={PeblProducts} />
          <Route path="/pebl/:cate" component={PeblProducts} />

          <Route exact path="/digital" component={DigitalProducts} />
          <Route path="/digital/:cate" component={DigitalProducts} />

          <Route path="/product/:id" component={ProductDetails} />

          <Route exact path="/basket" component={Basket} />

          <PrivateRoute exact path="/orders" component={Orders} />

          <PrivateRoute path="/order/:id" component={OrderDetails} />

          <Route component={FingotiNotFound} />
        </Switch>
      </Layout>
    </div>
  );
};

export default App;
