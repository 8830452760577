import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  FingotiLoading,
  FingotiButton,
  FingotiModalContent,
  FingotiModalActions,
} from "@fingoti/components";
import { useStripe } from "@stripe/react-stripe-js";

import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { useProfileState } from "../../context/ProfileContext";
import { apiService } from "../../services/api.service";
import { basketService } from "../../services/basket.service";
import { CardSelect } from "./CardSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    "& > :not(:first-child)": {
      marginTop: theme.spacing(2),
    },
  },
  orderDetails: {
    display: "flex",
    flexDirection: "column",
    "& > :not(:first-child)": {
      marginTop: theme.spacing(2),
    },
  },
  loading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  button: {
    width: "42%",
  },
}));

export const BasketCheckout = ({ showConfigSelect, setOpen, onOrderCreated }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading: profileLoading,
    loggedIn,
    organisation,
    addresses,
  } = useProfileState();
  const [thisLoading, setThisLoading] = useState(true);
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [selectedCard, setSelectedCard] = useState(undefined);
  const [poNumber, setPoNumber] = useState(undefined);
  const [customerRef, setCustomerRef] = useState(undefined);
  const [paymentIntent, setPaymentIntent] = useState(undefined);
  const [orderGuid, setOrderGuid] = useState(undefined);
  const [presets, setPresets] = useState([]);
  const [paymentError, setPaymentError] = useState(undefined);
  const [selectedPreset, setSelectedConfig] = useState("none");

  const getConfigs = useCallback(() => {
    apiService
      .getData("/organisation/presets")
      .then((reuslt) => {
        setPresets(reuslt.presets);
        setThisLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        setThisLoading(false);
      });
  }, [enqueueSnackbar]);

  const createOrder = () =>
    new Promise((resolve, reject) => {
      let body = {
        basket: basketService.basketValue(),
        deliveryAddress: deliveryAddress,
        billingAddress: billingAddress,
        poNumber: poNumber,
        customerReference: customerRef,
        presetId: selectedPreset !== "none" ? selectedPreset : null,
      };

      console.log("submitting", body);

      apiService
        .postData("/billing/invoices", body)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });

  const confirmPayment = (paymentIntent) =>
    new Promise((resolve, reject) => {
      stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: selectedCard,
        })
        .then((result) => {
          console.log(result);
          if (result.error) {
            reject(result.error);
          } else {
            resolve(result);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  const handlePaymentError = (error) => {
    console.error(error);
    setPaymentError(error);
    setThisLoading(false);
    enqueueSnackbar("Payment failed", { variant: "error" });
  };

  const handlePaymentSuccess = (orderId) => {
    setThisLoading(false);
    setOpen(false);
    enqueueSnackbar("Payment successful", { variant: "success" });
    history.push(`/order/${orderId}`);
  };

  const handlePayment = () => {
    setThisLoading(true);
    createOrder()
      .then((orderResult) => {
        console.log("order res", orderResult);
        setOrderGuid(orderResult.orderId);
        setPaymentIntent(orderResult.paymentIntent);
        onOrderCreated();
        confirmPayment(orderResult.paymentIntent)
          .then(() => {
            handlePaymentSuccess(orderResult.orderId);
          })
          .catch((paymentErr) => {
            handlePaymentError(paymentErr);
          });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Failed to create order", { variant: "error" });
      });
  };

  const handleRetryPayment = () => {
    setThisLoading(true);
    confirmPayment(paymentIntent)
      .then(() => {
        handlePaymentSuccess(orderGuid);
      })
      .catch((paymentErr) => {
        handlePaymentError(paymentErr);
      });
  };

  useEffect(() => {
    if (!profileLoading && loggedIn) {
      setBillingAddress(organisation.billingAddress);
      setDeliveryAddress(organisation.deliveryAddress);
      showConfigSelect ? getConfigs() : setThisLoading(false);
    }
  }, [
    organisation,
    profileLoading,
    loggedIn,
    addresses,
    getConfigs,
    showConfigSelect,
  ]);

  if (thisLoading) {
    return (
      <FingotiModalContent>
        <div className={classes.loading}>
          <FingotiLoading fullHeight={false} />
        </div>
      </FingotiModalContent>
    );
  } else {
    return (
      <>
        <FingotiModalContent>
          <div className={classes.root}>
            {!paymentError ? (
              <>
                <FormControl
                  fullWidth
                  required
                  variant="outlined"
                  style={{ height: "100%" }}
                >
                  <InputLabel id="deliveryAddress-label">
                    billing address
                  </InputLabel>
                  <Select
                    fullWidth
                    required
                    variant="outlined"
                    id="billingAddress"
                    value={billingAddress}
                    onChange={(e) => setBillingAddress(e.target.value)}
                    labelId="billingAddress-label"
                    label="billing address *"
                  >
                    {addresses.map((address, i) => {
                      return (
                        <MenuItem key={i} value={address.id}>
                          {address.line1},{" "}
                          {address.line2 ? `${address.line2}, ` : ""}{" "}
                          {address.postcode}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  required
                  variant="outlined"
                  style={{ height: "100%" }}
                >
                  <InputLabel id="deliveryAddress-label">
                    delivery address
                  </InputLabel>
                  <Select
                    fullWidth
                    required
                    variant="outlined"
                    id="deliveryAddress"
                    value={deliveryAddress}
                    onChange={(e) => setDeliveryAddress(e.target.value)}
                    labelId="deliveryAddress-label"
                    label="delivery address *"
                  >
                    {addresses.map((address, i) => {
                      return (
                        <MenuItem key={i} value={address.id}>
                          {address.line1},{" "}
                          {address.line2 ? `${address.line2}, ` : ""}{" "}
                          {address.postcode}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {showConfigSelect && (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    style={{ height: "100%" }}
                  >
                    <InputLabel id="config-label">preset</InputLabel>
                    <Select
                      fullWidth
                      variant="outlined"
                      id="config"
                      value={selectedPreset}
                      onChange={(e) => setSelectedConfig(e.target.value)}
                      labelId="config-label"
                      label="preset"
                    >
                      {presets.map((preset) => {
                        return (
                          <MenuItem key={preset.id} value={preset.id}>
                            {preset.presetNumber} - {preset.presetName}
                          </MenuItem>
                        );
                      })}
                      <MenuItem key="none" value="none">
                        No preset applied
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                <TextField
                  variant="outlined"
                  label="po number"
                  value={poNumber}
                  onChange={(e) => setPoNumber(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  label="customer ref"
                  value={customerRef}
                  onChange={(e) => setCustomerRef(e.target.value)}
                />
              </>
            ) : (
              <>
                <Typography>{paymentError.message}</Typography>
                <Typography>
                  Please choose a different card and try again. This order will
                  be avaliable in the orders list with options to pay should you
                  need to navigate away from this screen.
                </Typography>
              </>
            )}
            <CardSelect onChange={(card) => setSelectedCard(card)} />
          </div>
        </FingotiModalContent>
        {!thisLoading && (
          <FingotiModalActions>
            <div className={classes.buttonGroup}>
              <FingotiButton
                light
                type="submit"
                color="primary"
                disabled={Boolean(
                  !selectedCard || !deliveryAddress || !billingAddress
                )}
                className={classes.button}
                onClick={!paymentError ? handlePayment : handleRetryPayment}
              >
                {!paymentError ? "pay now" : "retry payment"}
              </FingotiButton>
              <FingotiButton
                light
                className={classes.button}
                onClick={() => setOpen(false)}
              >
                Cancel
              </FingotiButton>
            </div>
          </FingotiModalActions>
        )}
      </>
    );
  }
};
