import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch, Link as RouterLink } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { ProductsGrid } from './Shared/ProductsGrid';

const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '10%',
    },
    tab: {
        width: '100%',
    },
    tabTitle: {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    iconSpacing: {
        marginRight: theme.spacing(2),
        fontSize: '3rem',
    },
    tabRoot: {
        minWidth: 'unset',
    },
    tabLabelRight: {
        alignItems: 'unset',
        textAlign: 'right',
        paddingRight: theme.spacing(1),
    },
    boxPadding: {
        padding: theme.spacing(6),
        paddingTop: theme.spacing(3)
    }

}))

const TabPanel = (props) => {
    const { passClass, children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className={passClass}>
                    {children}
                </div>
            )}
        </div>
    );
}

const tabProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const RouterTab = (props) => <Tab component={RouterLink} {...props} />

export const VyneProducts = () => {

    const classes = useStyles();
    const match = useRouteMatch();
    const history = useHistory();
    const { cate } = useParams();
    const [value, setValue] = useState(0);
    const productLine = "Vyne";

    useEffect(() => {
        if (cate !== undefined) {
            switch (cate) {
                case "all":
                    setValue(0);
                    break;
                case "gateways":
                    setValue(1);
                    break;
                case "nodes":
                    setValue(2);
                    break;
                case "accessories":
                    setValue(3);
                    break;
                default:
                    setValue(0);
                    break;
            }
        } else {
            setValue(0);
        }
    }, [cate]);

    const handleChange = (event, newValue) => {

        let isTwoPartPath = match.path.indexOf('/', 1);
        let baseRoute = "/vyne";

        if (isTwoPartPath !== -1) {
            //Path is /foo/bar, need to get rid of the /bar
            baseRoute = match.path.slice(0, isTwoPartPath)
        }

        setValue(newValue);

        switch (newValue) {
            case 0:
                history.push(`${baseRoute}`);
                break;
            case 1:
                history.push(`${baseRoute}/gateways`);
                break;
            case 2:
                history.push(`${baseRoute}/nodes`);
                break;
            case 3:
                history.push(`${baseRoute}/accessories`);
                break;
            default:
                console.error("VyneProducts got invalid activeMenu prop");
                history.push('/');
                break;
        }
    }

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                value={value}
                variant="fullWidth"
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className={classes.tabs}
            >
                <RouterTab to='/vyne' classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="All" {...tabProps(0)} />
                <RouterTab to='/vyne/gateways' classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Gateways" {...tabProps(1)} />
                <RouterTab to='/vyne/nodes' classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Nodes" {...tabProps(2)} />
                <RouterTab to='/vyne/accessories' classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Accessories" {...tabProps(3)} />
            </Tabs>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={0}>
                <ProductsGrid productLine={productLine} category={undefined} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={1}>
                <ProductsGrid productLine={productLine} category={0} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={2}>
                <ProductsGrid productLine={productLine} category={[1, 2]} />
            </TabPanel>

            <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={3}>
                <ProductsGrid productLine={productLine} category={3} />
            </TabPanel>
        </div>
    )
}