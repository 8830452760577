export function getVariant(product, selectedOptions = []) {
  let variant;

  if (product.variants.length === 1 || selectedOptions.length === 0) {
    variant = product.variants[0];
  } else {
    variant = product.variants.find((v) =>
      v.selectedOptions.every((so) => selectedOptions.includes(so))
    );
  }

  return variant;
}

export function getVariantById(product, variantId) {
  return product.variants.find(v => v.id === variantId);
}

export function getPricing(product, variant) {
  return product.priceBreaks.find((pb) => pb.id === variant.priceBreak);
}

export function getPrice(
  product,
  variant,
  pricingTier,
  exVat = false,
  qty = 0
) {
  let priceBreak, priceRange, priceObj, price;

  //Get the correct pricebreak for the variant
  priceBreak = getPricing(product, variant);

  //Get correct price range
  if (qty !== 0) {
    priceRange = priceBreak.ranges.find((r) => r.from <= qty && r.to >= qty);
  } else {
    priceRange = priceBreak.ranges[0];
  }

  //Get correct price type
  priceObj = priceRange.prices.find((pr) => pr.type === pricingTier);

  //Fallback to the first price in the range
  if (!priceObj) {
    priceObj = priceRange.prices[0];
  }

  //Value is in pence, convert to £
  price = priceObj.price / 100;

  //Add VAT if required
  if (!exVat) {
    price = price * 1.2;
  }

  //Round price if not a fractional price
  if (product.productLine !== "Digital") {
    return price.toFixed(2);
  }

  return price;
}
