import { BehaviorSubject  } from 'rxjs';

const lsKey = "Fingoti-Basket";

const basketValue = (localStorage.getItem(lsKey) !== null) ? 
    JSON.parse(localStorage.getItem(lsKey)) : [];

const basket = new BehaviorSubject(basketValue);

const addItem = (stockId, variantId, qty) => {
    let currentBasket = basket.value;
    let existsInBasket = currentBasket.findIndex(item => item.stockId === stockId && item.variantId === variantId); 
    if(existsInBasket === -1) {
        //Item not currently in basket add a new item
        let item = { stockId: stockId, variantId: variantId, qty: qty }
        currentBasket.push(item);
    } else {
        //Item exists in basket - update the qty
        currentBasket[existsInBasket].qty = currentBasket[existsInBasket].qty + qty;
    }

    writeBasket(currentBasket)
}

const removeItem = (stockId, variantId) => {
    console.log(`Removing item ${stockId}`)

    let currentBasket = basket.value;
    let existsInBasket = currentBasket.findIndex(item => item.stockId === stockId && item.variantId === variantId); 

    //If item is in basket
    if(existsInBasket > -1) {
        //Remove that item
        currentBasket.splice(existsInBasket, 1);
        console.log("Removed")

        writeBasket(currentBasket);
    }
}

const updateItemQty = (variantId, newQty) => {
    let currentBasket = basket.value;
    let existsInBasket = currentBasket.findIndex(item => item.variantId === variantId);
    currentBasket[existsInBasket].qty = newQty;
    writeBasket(currentBasket);
}

const writeBasket = (currentBasket) => {
    console.log("Writing basket...")
    basket.next(currentBasket);
    localStorage.setItem(lsKey, JSON.stringify(basket.value))
}

const emptyBasket = () => {
    console.log("Clearing basket...");
    basket.next([]);
    localStorage.removeItem(lsKey);
}

const itemsCount = () => {
    let totalItems = 0;
    basket.value.forEach(el => {
        totalItems += el.qty;
    });
    return totalItems;
}

export const basketService = {
    basketObservable: basket.asObservable(),
    basketValue () { return basket.value },
    addItem,
    removeItem,
    updateItemQty,
    emptyBasket, 
    itemsCount
}