import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({

    lightButton: {
        backgroundColor: theme.palette.success.light,
    }

}))

export const BasketButton = () => {
    const classes = useStyles();
    const history = useHistory();

  return (
    <Button className={classes.lightButton} onClick={() => history.push("/basket")}>
      View Basket
    </Button>
  );
};
