import { forwardRef, useCallback, useEffect, useState } from "react";

//eslint-disable-next-line
import { ModelViewer, ModelViewerElement } from "@google/model-viewer";
import { makeStyles } from "@material-ui/core";

import { cdnUrl } from "../../../services/config";

import "react-image-gallery/styles/css/image-gallery.css";
import ReactImageGallery from "react-image-gallery";

const useMainStyles = makeStyles((theme) => ({
  "@global": {
    ".model": {
      height: "100%",
      width: "100%",
    },
    ".image-gallery-thumbnail": {
      padding: 2 + "px !important",
    },
    ".image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover, .image-gallery-thumbnail.active":
      {
        border: `1px solid ${theme.palette.primary.main} !important`,
        background: theme.palette.primary.main,
        borderRadius: 4,
      },
    ".image-gallery-icon": {
      filter: "drop-shadow(0 0px 1px #1a1a1a);",
    },
    ".image-gallery-icon:hover": {
      color: theme.palette.primary.main,
    },
    ".image-gallery-icon:focus": {
      outline: "none",
    },
  },
}));

const ProductImageGallery = forwardRef(({ product, startIndex, showModel = true }, ref) => {
  //Unused but don't remove, adds global styles for image gallery
  const classes = useMainStyles();
  const [hasModel, setHasModel] = useState(false);

  const tryGetThreeDModel = useCallback(() => {
    fetch(`${cdnUrl}/images/models/${product.id}.glb`, {
      method: "HEAD",
    }).then((response) => {
      if (response.status === 200) {
        setHasModel(true);
      }
    });
  }, [product.id]);

  useEffect(() => {
    tryGetThreeDModel();
  }, [tryGetThreeDModel]);

  const Model = () => (
    <div>
      <model-viewer
        src={`${cdnUrl}/images/models/${hasModel ? product.id : "default"}.glb`}
        auto-rotate
        camera-controls
        class="image-gallery-image"
        style={{ width: "100%", height: `100%`, aspectRatio: "16 / 9" }}
      />
    </div>
  );

  const images = product.images.map((img) => ({
    original: `${cdnUrl}/static/products/${product.id}/${img.filename}`,
    thumbnail: `${cdnUrl}/static/products/${product.id}/${img.filename}`,
    originalAlt: img.alt,
    thumbnailAlt: img.alt,
  }));

  if (showModel) {
    images.unshift({
      thumbnail: `${cdnUrl}/static/products/${product.id}/image0.jpg`,
      renderItem: Model,
    });
  }

  return (
    <ReactImageGallery
      ref={ref}
      items={images}
      startIndex={startIndex}
      showFullscreenButton={false}
      showPlayButton={false}
      thumbnailPosition="right"
    />
  );
});

export default ProductImageGallery;
