import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FingotiLoading } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import amex from 'payment-icons/min/flat/amex.svg';
import diners from 'payment-icons/min/flat/diners.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import jcb from 'payment-icons/min/flat/jcb.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import unionpay from 'payment-icons/min/flat/unionpay.svg';
import visa from 'payment-icons/min/flat/visa.svg';

import { apiService } from '../../services/api.service';

const useStyles = makeStyles(theme => ({

    brandIcon: {
        verticalAlign: 'middle',
        width: '42px',
        marginRight: theme.spacing(1)
    },

}))

export const CardSelect = ({ onChange, className }) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [thisLoading, setThisLoading] = useState(true);
    const [cards, setCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState('');

    useEffect(() => {
        setThisLoading(true);
        getCards();
    }, [])

    const getCards = () => {
        apiService.getData('/billing/cards')
            .then(result => {
                setCards(result.cards);
                setSelectedCard(getDefaultSelected(result));
                onChange && onChange(getDefaultSelected(result));
                setThisLoading(false);
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: 'error' });
                setThisLoading(false);
            })
    }

    const getDefaultSelected = (cards) => {
        if (cards.defaultCard) {
            return cards.defaultCard
        } else {
            if (cards.cards.length > 0)
                return cards.cards[0].id
        }
    }

    return (
        thisLoading ?
            <FingotiLoading fullHeight={false} text={false} />
            :
            <FormControl fullWidth variant='outlined' className={className}>
                <InputLabel id='paymentMethod-label'>payment method</InputLabel>
                <Select
                    fullWidth
                    value={cards.length > 0 ? selectedCard : "none"}
                    onChange={(e) => { setSelectedCard(e.target.value); onChange(e.target.value); }}
                    variant='outlined'
                    labelId='paymentMethod-label'
                    label='payment method'
                >
                    {
                        cards.length > 0 ?
                            cards.map((card, i) => {

                                let cardIcon;

                                switch (card.card.brand) {
                                    case "amex":
                                        cardIcon = amex;
                                        break;
                                    case "diners":
                                        cardIcon = diners;
                                        break;
                                    case "discover":
                                        cardIcon = discover;
                                        break;
                                    case "jcb":
                                        cardIcon = jcb;
                                        break;
                                    case "mastercard":
                                        cardIcon = mastercard;
                                        break;
                                    case "unionpay":
                                        cardIcon = unionpay;
                                        break;
                                    case "visa":
                                        cardIcon = visa;
                                        break;
                                    default:
                                        cardIcon = "unk";
                                        break;
                                }

                                return (
                                    <MenuItem key={`${i}`} value={card.id}>
                                        <img src={cardIcon} className={classes.brandIcon} /> **** **** **** {card.card.last4}
                                    </MenuItem>
                                )
                            })
                            :
                            <MenuItem key="1" value="none">
                                No cards on file
                            </MenuItem>
                    }
                </Select>
            </FormControl>
    );

}