import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useHistory } from "react-router";

import makeStyles from "@material-ui/core/styles/makeStyles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import PaymentIcon from "@material-ui/icons/Payment";
import DirectionsRunOutlinedIcon from "@material-ui/icons/DirectionsRunOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import MarkunreadMailboxOutlinedIcon from "@material-ui/icons/MarkunreadMailboxOutlined";

import { datetimeService } from "../../services/datetime.service";

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  statusIcon: {
    color: theme.palette.primary.main,
    verticalAlign: "middle",
  },
  statusIconComplete: {
    color: theme.palette.success.main,
  },
}));

export const OrderRow = ({ order, exVat }) => {
  const history = useHistory();
  const classes = useStyles();
  const [itemCount, setItemCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let itemRunning = 0,
      totalRunning = 0,
      status = "";

    order.items.physical.forEach((item) => {
      itemRunning += item.quantityOrdered;
      totalRunning += item.itemPrice * item.quantityOrdered;
    });

    //Each order item counts as 1 item in the basket, multiply the fractional cost by quantity to get item cost
    order.items.digital.forEach((item) => {
      itemRunning += 1;
      totalRunning += item.itemPrice * item.quantity;
    });

    switch (order.orderStatus) {
      case 0:
      case 1:
        status = {
          icon: <ReceiptOutlinedIcon className={classes.statusIcon} />,
          tooltip: "recieved",
        };
        break;
      case 2:
      case 3:
        status = {
          icon: <PaymentIcon className={classes.statusIcon} />,
          tooltip: "paid",
        };
        break;
      case 4:
        status = {
          icon: <DirectionsRunOutlinedIcon className={classes.statusIcon} />,
          tooltip: "picked",
        };
        break;
      case 5:
        status = {
          icon: <LocalShippingOutlinedIcon className={classes.statusIcon} />,
          tooltip: "dispatched",
        };
        break;
      case 6:
        status = {
          icon: (
            <MarkunreadMailboxOutlinedIcon
              className={clsx(classes.statusIcon, classes.statusIconComplete)}
            />
          ),
          tooltip: "delivered",
        };
        break;
      default:
        console.error("unhandled order status");
        break;
    }

    let orderTotal =
      order.billingAddress.country === "GB"
        ? (totalRunning / 100) * 1.2
        : totalRunning / 100;

    setStatus(status);
    setItemCount(itemRunning);
    setTotal(orderTotal);
    setLoading(false);
  }, [order, exVat]);

  if (loading) {
    return <TableRow></TableRow>;
  } else {
    return (
      <TableRow>
        <TableCell>{order.orderNumber}</TableCell>
        <TableCell>
          {datetimeService.formatDateTimeNoSeconds(new Date(order.orderedDate))}
        </TableCell>
        <TableCell align="center">
          <Tooltip title={status.tooltip}>{status.icon}</Tooltip>
        </TableCell>
        <TableCell align="center">{itemCount}</TableCell>
        <TableCell>£0.00</TableCell>
        <TableCell>£{total.toFixed(2)}</TableCell>
        <TableCell>
          <Tooltip title="order details">
            <IconButton
              className={classes.actionIcon}
              onClick={() => history.push(`/order/${order.id}`)}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }
};
