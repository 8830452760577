//Category ENUMS
//undefined = all
// 0 = gateway (Pebl and Vyne Gateway)
// 1 = sensor
// 2 = power (PSU/PoE/UPS)
// 3 = Accessory (Cables, adapters etc)
// 4 = Digital (Ripples, SMS etc)

export const CategoryEnum = {
    0: {
        label: 'Gateways',
        route: 'gateways'
    },
    1: {
        label: 'Nodes',
        route: 'nodes'
    },
    3: {
        label: 'Accessories',
        route: 'accessories'
    },
    4: {
        label: 'Ripples',
        route: 'ripples'
    }
}

export const StatusEnum = {
    0: "Disabled",
    1: "Active",
    2: "Discontinued",
    3: "Coming Soon",
    4: "New",
    5: "On Sale",
    6: "Last Few",
}