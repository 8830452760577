import React, { createContext, useContext, useReducer } from "react";

const initialState = {
  stock: undefined,
  exVat: false,
  vatExempt: true,
  pricingTier: 0,
  loading: true,
  error: false,
};

const StoreStateContext = createContext();
const StoreDispatchContext = createContext();

const StoreReducer = (state, action) => {
  let pay = action.payload;
  switch (action.type) {
    case "status":
      return {
        ...state,
        loading: pay.loading !== undefined ? pay.loading : state.loading,
        error: pay.error !== undefined ? pay.error : state.error,
      };
    case "set_exvat":
      return {
        ...state,
        exVat: pay,
      };
    case "set_pricing_tier": {
      console.log("set pt", pay);
      return {
        ...state,
        pricingTier: pay,
      };
    }
    case "add_stock":
      return {
        ...state,
        stock: pay.stock,
      };
    case "set_vat_exempt":
      return {
        ...state,
        vatExempt: pay.vatExempt,
      };
    default:
      console.error(
        `Unhandled action dispatched to store reducer, action type was: ${action.type}`
      );
      return state;
  }
};

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(StoreReducer, initialState);

  return (
    <StoreStateContext.Provider value={state}>
      <StoreDispatchContext.Provider value={dispatch}>
        {children}
      </StoreDispatchContext.Provider>
    </StoreStateContext.Provider>
  );
};

const useStoreState = () => {
  const context = useContext(StoreStateContext);

  if (context === undefined) {
    throw new Error("useStoreState must be used within a StoreContextProvider");
  }

  return context;
};

const useStoreDispatch = () => {
  const context = useContext(StoreDispatchContext);

  if (context === undefined) {
    throw new Error(
      "useStoreDispatch must be used within a StoreContextProvider"
    );
  }

  return context;
};

export { StoreProvider, useStoreDispatch, useStoreState };
