import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "calc(100% + 48px)",
    zIndex: 1,
    margin: "-24px -24px 0px -24px",
    marginRight: "auto",
    marginLeft: "auto",
    display: "block",

    [theme.breakpoints.up("sm")]: {
      margin: "-24px -24px 0px -24px",
      marginBottom: theme.spacing(12),
      "&:not(:first-child)": {
        marginTop: theme.spacing(12),
      },
    },

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(6),
      "&:not(:first-child)": {
        marginTop: theme.spacing(6),
      },
    },
  },
  link: {
    cursor: "pointer",
  },
}));

export const Image = ({ src, alt, link }) => {
  const classes = useStyles();

  return link ? (
    <img
      alt={alt}
      className={classes.image}
      style={{ cursor: "pointer" }}
      src={"https://cdn.fingoti.com/images/static" + src}
      onClick={() => window.open(link)}
    />
  ) : (
    <img
      alt={alt}
      className={classes.image}
      src={"https://cdn.fingoti.com/images/static" + src}
    />
  );
};
