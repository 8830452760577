import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
//eslint-disable-next-line
import {
  FingotiLoading,
  FingotiButton,
  FingotiHeader,
} from "@fingoti/components";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useStoreState } from "../../../context/StoreContext";
import { basketService } from "../../../services/basket.service";
import { CategoryEnum } from "./enums";
import { VATToggle } from "./VATToggle";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import ProductDetailsTabs from "./ProductDetailsTabs";
import ProductImageGallery from "./ProductImageGallery";
import { QuantitySelect } from "../../QuantitySelect";
import {
  getPrice,
  getPricing,
  getVariant,
  getVariantById,
} from "../../Utils/productDataHelpers";
import { BasketButton } from "./BasketButton";

const useMainStyles = makeStyles((theme) => ({
  productInfo: {
    paddingLeft: "25px",
  },
  quantitySelect: {
    marginRight: theme.spacing(3),
  },
  basketButton: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  datasheet: {
    display: "flex",
    alignItems: "center",
  },
  datasheetIcon: {
    marginRight: "5px",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
    marginBottom: "15px",
  },
  description: {
    marginTop: "30px",
  },
  productOptions: {
    width: "70%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "& > :not(:first-child)": {
      marginTop: theme.spacing(2),
    },
  },
  productOption: {},
}));

const useTableStyles = makeStyles((theme) => ({
  tableHead: {
    color: theme.palette.primary.main,
  },
}));

const ProductPricingTable = ({
  product,
  variant,
  pricingTier,
  pricing,
  exVat,
  vatExempt,
}) => {
  const classes = useTableStyles();

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>quantity</TableCell>
            <TableCell className={classes.tableHead}>
              price {!vatExempt && (exVat ? "(ex" : "(inc") + " VAT)"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pricing.ranges.map((p, i) => {
            return (
              <TableRow key={i}>
                <TableCell>
                  {i + 1 !== pricing.ranges.length
                    ? `${p.from} - ${p.to}`
                    : `${p.from}+`}
                </TableCell>
                <TableCell>
                  £{getPrice(product, variant, pricingTier, exVat, p.from + 1)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ProductOption = ({ option, onChange, selectedOptions }) => {
  const classes = useMainStyles();
  return (
    <FormControl
      fullWidth
      required
      variant="outlined"
      className={classes.productOption}
    >
      <InputLabel id={`${option.key}-label`}>{option.key}</InputLabel>
      <Select
        fullWidth
        required
        variant="outlined"
        name={option.key}
        value={selectedOptions[option.key]}
        onChange={onChange}
        labelId={`${option.key}-label`}
        label={option.key}
      >
        {option.optionValues.map((ov) => (
          <MenuItem key={ov.id} value={ov.id}>
            {ov.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const ProductDetails = () => {
  const history = useHistory();
  const classes = useMainStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const {
    stock,
    exVat,
    vatExempt,
    pricingTier,
    loading: storeLoading,
    error,
  } = useStoreState();
  const [product, setProduct] = useState({});
  const [variant, setVariant] = useState({});
  const [pricing, setPricing] = useState({});
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [imageIndex, setImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleAddToBasket = () => {
    let currentItem = basketService
      .basketValue()
      .find((bi) => bi.stockId === product.id && bi.variantId === variant.id);

    if (currentItem) {
      if (currentItem.qty + parseInt(quantity) > variant.stock) {
        //Tell user can't excced max stock level
        enqueueSnackbar(
          `You cannot request more than the ${variant.stock} avaliable stock of this item`,
          { variant: "error" }
        );
        return;
      }
    }

    basketService.addItem(product.id, variant.id, parseInt(quantity));
    let addedMsg =
      product.productLine !== "Digital"
        ? `Added ${quantity} item${parseInt(quantity) > 1 ? "s" : ""} to basket`
        : "Added to basket";

    enqueueSnackbar(addedMsg, { variant: "success", action: BasketButton });
  };

  const handleQtyChange = (newQty) => {
    setQuantity(newQty);
    setPrice(getPrice(product, variant, pricingTier, exVat, newQty));
  };

  const actionButtons = () => {
    return (
      <React.Fragment>
        <VATToggle display={!vatExempt}/>
      </React.Fragment>
    );
  };

  const setDefaultOptions = (product) => {
    let opts = {};
    product.options.forEach((o) => {
      opts[o.key] = o.optionValues[0].id;
    });

    setSelectedOptions(opts);
  };

  const resolveSelectedOptions = (product, variant) => {
    let opts = {};

    //Loop round the array of selectedOptions
    variant.selectedOptions.forEach((so) => {
      //Loop round the options for the product
      product.options.forEach((po) => {
        //Try and find the selectedOption in the possible values for the object
        let opt = po.optionValues.find((ov) => ov.id === so);
        //If found, add it to selectedOptions object
        if (opt) {
          opts[po.key] = opt.id;
        }
      });
    });

    return opts;
  };

  const findProduct = useCallback(() => {
    let idParts = id.split("-");
    let product = stock.find((s) => s.id === idParts[0]);
    if (product) {
      let variant, pricing;

      if (idParts.length > 1) {
        variant = getVariantById(product, id);
        setSelectedOptions(resolveSelectedOptions(product, variant));
        setImageIndex(variant.imageIndex + 1);
      } else {
        variant = getVariant(product);
        setDefaultOptions(product);
      }

      pricing = getPricing(product, variant);

      setProduct(product);
      setVariant(variant);
      setPricing(pricing);
      setQuantity(variant.stock > 0 ? product.quantity.increment : 0);
      setPrice(getPrice(product, variant, pricingTier, exVat));
      setLoading(false);
    } else {
      history.goBack();
    }
  }, [stock, id, pricingTier]);

  const handleOptionChange = (e) => {
    //Set new option value in object
    let options = { ...selectedOptions, [e.target.name]: e.target.value };

    //Find new variant based on options
    let variant = getVariant(product, Object.values(options));

    history.replace({ pathname: `./${variant.id}` });
  };

  useEffect(() => {
    if (product !== {} && variant !== {} && quantity !== 0) {
      setPrice(getPrice(product, variant, pricingTier, exVat, quantity));
    }
  }, [exVat]);

  useEffect(() => {
    if (!storeLoading && !error) {
      findProduct();
    }
  }, [stock, storeLoading, error, findProduct]);

  if (loading) {
    return <FingotiLoading />;
  } else {
    const breadcrumbs = [
      { text: "Store", link: "/" },
      {
        text: product.productLine,
        link: `/${product.productLine.toLocaleLowerCase()}`,
      },
      {
        text: CategoryEnum[product.category].label,
        link: `/${product.productLine.toLocaleLowerCase()}/${
          CategoryEnum[product.category].route
        }`,
      },
      { text: product.productName },
    ];

    return (
      <Box p={3}>
        <FingotiHeader
          breadcrumbs={breadcrumbs}
          sectionIcon={ShoppingCartIcon}
          actionButtons={actionButtons()}
          className={classes.header}
        />
        <Grid container spacing={4}>
          <Grid item xs={6} className={classes.media}>
            <ProductImageGallery
              startIndex={imageIndex}
              product={product}
              showModel={product.productLine !== "Digital"}
            />
          </Grid>
          <Grid item xs={6} className={classes.productInfo}>
            <Grid container>
              <Grid item xs={7}>
                <Typography style={{ color: "#FD8E1F", fontSize: "14pt" }}>
                  {product.id}
                </Typography>
                <br />
                <Typography variant="h4">{product.productLine}</Typography>
                <Typography variant="h5">{product.productName}</Typography>
                <div className={classes.productOptions}>
                  {product.options.length > 0 &&
                    product.options.map((o) => (
                      <ProductOption
                        option={o}
                        onChange={handleOptionChange}
                        selectedOptions={selectedOptions}
                      />
                    ))}
                </div>
                <Typography
                  variant="h5"
                  style={{ color: "#3696FF", fontSize: "24pt" }}
                >
                  £{price}{" "}
                  <small style={{ color: "#A0A0A0", fontSize: "16pt" }}>
                    {!vatExempt && (exVat ? "ex VAT" : "inc VAT")}
                  </small>
                </Typography>
                <br />
                <Typography variant="subtitle1">
                  {product.productLine === "Digital" ? (
                    <span>&#8734;</span>
                  ) : (
                    variant.stock
                  )}{" "}
                  in stock
                </Typography>
                <br />
                <Box display="flex" alignItems="center">
                  <QuantitySelect
                    disabled={variant.stock < 1}
                    quantity={quantity}
                    setQuantity={handleQtyChange}
                    increment={product.quantity.increment}
                    minValue={product.quantity.min}
                    className={classes.quantitySelect}
                    maxValue={
                      variant.stock < product.quantity.max
                        ? variant.stock
                        : product.quantity.max
                    }
                  />
                  <FingotiButton
                    disabled={
                      variant.stock < 1 ||
                      product.status === 0 ||
                      product.status === 2 ||
                      product.status === 3
                    }
                    light
                    color="primary"
                    className={classes.basketButton}
                    onClick={() => handleAddToBasket(product.id, quantity)}
                  >
                    Add to basket
                  </FingotiButton>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <ProductPricingTable
                  product={product}
                  variant={variant}
                  pricing={pricing}
                  pricingTier={pricingTier}
                  exVat={exVat}
                  vatExempt={vatExempt}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ProductDetailsTabs product={product} />
          </Grid>
        </Grid>
      </Box>
    );
  }
};
