import React, { useEffect, useState, useCallback } from "react";
import { useSnackbar } from "notistack";
import {
  FingotiLoading,
  FingotiHeader,
  FingotiMarkdown,
} from "@fingoti/components";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import { apiService } from "../../services/api.service";
import { useProfileState } from "../../context/ProfileContext";
import { OrderRow } from "./OrderRow";
import { useStoreState } from "../../context/StoreContext";
import { VATToggle } from "../Shop/Shared/VATToggle";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
  },
  tableHead: {
    color: theme.palette.primary.light,
  },
  noItemsRow: {
    textAlign: "center",
    height: theme.spacing(32),
    borderBottom: "none",
  },
}));

export const Orders = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { organisation, loading: profileLoading } = useProfileState();
  const [orders, setOrders] = useState();
  const [loading, setLoading] = useState(true);

  const getOrders = useCallback(() => {
    setLoading(true);
    apiService
      .getData("/store/orders")
      .then((result) => {
        if (result.orders.length > 0) {
          let sortedOrders = result.orders.sort(
            (a, b) => new Date(b.orderedDate) - new Date(a.orderedDate)
          );
          setOrders(sortedOrders);
        } else {
          setOrders(result.orders);
        }
        setLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        setLoading(false);
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (organisation && !profileLoading) {
      getOrders();
    }
  }, [organisation, profileLoading, getOrders]);

  const actionButtons = () => {
    return <></>;
  };

  const breadcrumbs = [
    { text: "Store", link: "/" },
    { text: "Orders", link: "" },
  ];

  if (loading) {
    return <FingotiLoading />;
  } else {
    return (
      <Box p={3}>
        <FingotiHeader
          breadcrumbs={breadcrumbs}
          sectionIcon={ShoppingCartIcon}
          actionButtons={actionButtons()}
          className={classes.header}
        />
        <FingotiMarkdown path="/documentation/store/orders/index.md" />
        <TableContainer>
          <Table className={classes.root}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>
                  order number
                </TableCell>
                <TableCell className={classes.tableHead}>date</TableCell>
                <TableCell className={classes.tableHead} align="center">
                  status
                </TableCell>
                <TableCell className={classes.tableHead} align="center">
                  item count
                </TableCell>
                <TableCell className={classes.tableHead}>postage</TableCell>
                <TableCell className={classes.tableHead}>total</TableCell>
                <TableCell className={classes.tableHead}>actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders && orders.length > 0 ? (
                orders.map((order, i) => {
                  return <OrderRow key={i} order={order} />;
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7} className={classes.noItemsRow}>
                    You currently have no orders
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
};
