import React from 'react';
import ReactDOM from 'react-dom';

import AppWrapper from './AppWrapper';
import registerServiceWorker from './registerServiceWorker';
import './index.css';

let env = process.env.REACT_APP_ENVIROMENT_STRING;

console.info(`Running Fingoti Store ${env} build`);

if(env === "PROD") {
    console.log = () => {}
    console.info = () => {}
    console.error = () => {}
} 

const rootElement = document.getElementById('root');

ReactDOM.render(<AppWrapper />, rootElement);

registerServiceWorker();

