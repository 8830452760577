import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import makeStyles from "@material-ui/core/styles/makeStyles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import { apiService } from "../../services/api.service";

import { DeviceIcon } from "../../icons/FingotIcons";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DvrOutlinedIcon from "@material-ui/icons/DvrOutlined";

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  itemWrapper: {
    display: "flex",
    alignItems: "center",
    "& > :first-child": {
      marginRight: theme.spacing(2),
    },
  },
  storeKey: {
    display: "flex",
    flexDirection: "column",
    "& > p": {
      margin: 0,
    },
  },
}));

export const OrderItemRow = ({ item, exVat }) => {
  const classes = useStyles();
  const history = useHistory();
  const [keyMessage, setKeyMessage] = useState("");

  const checkKey = (value) => {
    apiService
      .getData(`/store/key/${value}`)
      .then((result) => {
        setKeyMessage(result.message);
      })
      .catch((error) => {
        //Not actually an error but will be success: false if key has been used
        setKeyMessage(error);
      });
  };

  useEffect(() => {
    item.key && checkKey(item.key);
  }, []);

  return (
    <TableRow key={item.id}>
      <TableCell>{item.itemId}</TableCell>
      <TableCell>
        <div className={classes.itemWrapper}>
          {item.productLine === "Digital" ? (
            <DvrOutlinedIcon fontSize="large" />
          ) : (
            <DeviceIcon fontSize="large" />
          )}
          {item.key ? (
            <div className={classes.storeKey}>
              <p>{item.name}</p>
              <p>
                {item.key
                  ? `${item.key.match(/.{1,4}/g).join("-")} - ${keyMessage} `
                  : ""}
              </p>
            </div>
          ) : (
            item.name
          )}
        </div>
      </TableCell>
      <TableCell align="center">{item.qty}</TableCell>
      <TableCell>
        £
        {item.productLine === "Digital"
          ? exVat
            ? item.price
            : item.price * 1.2
          : exVat
          ? item.price.toFixed(2)
          : (item.price * 1.2).toFixed(2)}
      </TableCell>
      <TableCell>
        £{exVat ? item.lineTotal.toFixed(2) : (item.lineTotal * 1.2).toFixed(2)}
      </TableCell>
      <TableCell>
        <Tooltip title="item details">
          <IconButton
            className={classes.actionIcon}
            onClick={() => history.push(`/product/${item.itemId}`)}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
