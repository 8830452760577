import React from "react";
import { FingotiSwitchButton } from "@fingoti/components";

import { useStoreState, useStoreDispatch } from "../../../context/StoreContext";

export const VATToggle = ({ display = true }) => {
  const { exVat } = useStoreState();
  const dispatch = useStoreDispatch();

  return display ? (
    <FingotiSwitchButton
      leftText="ex VAT"
      rightText="inc VAT"
      value={!exVat}
      onClick={() => dispatch({ type: "set_exvat", payload: !exVat })}
    />
  ) : (
    <></>
  );
};
