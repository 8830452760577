import React, { useState } from "react";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { useStripe } from "@stripe/react-stripe-js";
import {
  FingotiLoading,
  FingotiButton,
  FingotiModalActions,
  FingotiModalContent,
} from "@fingoti/components";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { CardSelect } from "../Basket/CardSelect";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    "& > :not(:first-child)": {
      marginTop: theme.spacing(2),
    },
  },
  tableHead: {
    color: theme.palette.primary.main,
  },
  spacing: {
    margin: theme.spacing(2) + "px auto",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  button: {
    width: "42%",
  },
}));

export const OrderPay = ({ setOpen, onDone, inv }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCard, setSelectedCard] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(undefined);

  const handlePaymentError = (error) => {
    console.error(error);
    setPaymentError(error);
    setLoading(false);
    enqueueSnackbar("Payment failed", { variant: "error" });
  };

  const confirmPayment = (paymentIntent) =>
    new Promise((resolve, reject) => {
      stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: selectedCard,
        })
        .then((result) => {
          console.log(result);
          if (result.error) {
            reject(result.error);
          } else {
            resolve(result);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  const handleRetryPayment = () => {
    setLoading(true);
    confirmPayment(inv.payment_intent)
      .then(() => {
        enqueueSnackbar("Payment successful", { variant: "success" });
        onDone();
      })
      .catch((paymentErr) => {
        handlePaymentError(paymentErr);
      });
  };

  //   const tryPayment = async () => {
  //     setLoading(true);
  //     let { error, paymentIntent } = await stripe.confirmCardPayment(
  //       inv.payment_intent.client_secret,
  //       { payment_method: selectedCard }
  //     );

  //     if (error) {
  //       console.error(error);
  //       setSubmitted(false);
  //       enqueueSnackbar("Payment failed", { variant: "error" });
  //       onDone();
  //     } else {
  //       console.log("[payment intent]", paymentIntent);
  //       setSubmitted(false);
  //       enqueueSnackbar("Payment successful", { variant: "success" });
  //       onDone();
  //     }
  //   };

  if (loading) {
    return (
      <FingotiModalContent>
        <div className={classes.loading}>
          <FingotiLoading fullHeight={false} />
        </div>
      </FingotiModalContent>
    );
  } else {
    return (
      <>
        <FingotiModalContent>
          <div className={classes.root}>
            <Typography>This invoice is unpaid</Typography>
            <Typography className={classes.failMsg}>
              {!paymentError
                ? inv.payment_intent.charges.data.length > 0
                  ? inv.payment_intent.charges.data[0].failure_message
                  : ""
                : paymentError.message}
            </Typography>
            <Typography>Please select a payment method</Typography>
            <CardSelect
              className={clsx(classes.spacing, classes.select)}
              onChange={setSelectedCard}
            />
          </div>
        </FingotiModalContent>
        <FingotiModalActions>
          <div className={classes.buttonGroup}>
            <FingotiButton
              light
              color="primary"
              className={classes.button}
              disabled={!selectedCard}
              onClick={handleRetryPayment}
            >
              pay invoice
            </FingotiButton>
            <FingotiButton
              light
              className={classes.button}
              onClick={() => setOpen(false)}
            >
              CANCEL
            </FingotiButton>
          </div>
        </FingotiModalActions>
      </>
    );
  }
};
