import React from "react";
import clsx from 'clsx';
import { useHistory } from "react-router";
import { FingotiButton, FingotiMarkdown } from "@fingoti/components";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import { Image } from "../Image";

import Pebl from "../../images/Pebl.jpg";
import { cdnUrl, webUrl } from "../../services/config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
  },
  titleTop: {
    display: "flex",
    justifyContent: "flex-end",
  },
  contentTop: {
    textAlign: "right",
    marginTop: "15px",
    marginLeft: "10px",
  },
  buttonGroupTop: {
    position: "absolute",
    bottom: "15px",
    right: "0px",
    paddingRight: "12px",
    paddingBottom: "12px",
  },
  imageBottom: {
    display: "flex",
    justifyContent: "flex-end",
  },
  contentBottom: {
    marginTop: "15px",
    marginRight: "10px",
  },
  buttonGroupBottom: {
    position: "absolute",
    bottom: "15px",
    left: "0px",
    paddingLeft: "12px",
    paddingBottom: "12px",
  },
  button: {
    marginLeft: "5px",
  },
  vyneText: {
      paddingRight: 128
  },
  peblText: {
      paddingLeft: 128
  }
}));

export const Shop = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <Image src="/_MG_7678.jpg" alt="" />
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={5} className={classes.root}>
            <Typography variant="h3" className={classes.titleTop}>
              Pebl
            </Typography>

            <Grid container>
              <Typography variant="subtitle1" className={clsx(classes.contentTop, classes.peblText)}>
                <FingotiMarkdown path="/documentation/store/pebl.md" />
              </Typography>
            </Grid>

            <div className={classes.buttonGroupTop}>
              <FingotiButton
                light
                onClick={() => window.location.assign(`${webUrl}/pebl`)}
              >
                More Information
              </FingotiButton>
              <FingotiButton
                light
                className={classes.button}
                color="primary"
                onClick={() => history.push("/pebl")}
              >
                Buy
              </FingotiButton>
            </div>
          </Grid>

          <Grid item xs={7}>
            <img src={`${cdnUrl}/images/static/Store_Home_Pebl.png`} height="300px" alt="A pebl" />
          </Grid>
        </Grid>
      </div>

      <div
        className={classes.root}
        style={{ marginTop: "48px", marginBottom: "96px" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={7} className={classes.imageBottom}>
            <img src={`${cdnUrl}/images/static/Store_Home_Vyne.png`} height="300px" alt="A pebl" />
          </Grid>

          <Grid item xs={5} className={classes.root}>
            <Typography variant="h3" className={classes.titleBottom}>
              Vyne
            </Typography>

            <Grid container>
              <Typography variant="subtitle1" className={clsx(classes.contentBottom, classes.vyneText)}>
                <FingotiMarkdown path="/documentation/store/vyne.md" />
              </Typography>
            </Grid>

            <div className={classes.buttonGroupBottom}>
              <FingotiButton
                light
                color="primary"
                onClick={() => history.push("/vyne")}
              >
                Buy
              </FingotiButton>
              <FingotiButton
                light
                className={classes.button}
                onClick={() => window.location.assign(`${webUrl}/vyne`)}
              >
                More Information
              </FingotiButton>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
