import React from 'react';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';

import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import DirectionsRunOutlinedIcon from '@material-ui/icons/DirectionsRunOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import { FingotiMarkdown } from '@fingoti/components';


const useStepIconStyles = makeStyles(theme => ({

    root: {
        zIndex: 1,
        color: theme.palette.greyThree.main,
        width: 80,
        height: 80,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
    },
    active: {
        color: theme.palette.primary.main,
    },
    completed: {
        color: theme.palette.success.main,
    },

}));

const useStyles = makeStyles(theme => ({

    stepText: {
        textAlign: 'center',
        marginTop: theme.spacing(4)
    }

}))


const CustomStepIcon = (props) => {

    const classes = useStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <ReceiptOutlinedIcon fontSize="large" />,
        2: <PaymentIcon fontSize="large" />,
        3: <DirectionsRunOutlinedIcon fontSize="large" />,
        4: <LocalShippingOutlinedIcon fontSize="large" />,
        5: <MarkunreadMailboxOutlinedIcon fontSize="large" />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

export const OrderProgress = ({ status, className }) => {

    const theme = useTheme();
    const classes = useStyles();

    const steps = [
        "Recieved",
        "Paid",
        "Picked",
        "Dispatched",
        "Delivered"
    ]

    let activeStep, stepText;
    const rootPath = "/documentation/store/orders";

    switch (status) {
        case 0:
        case 1:
            activeStep = 1;
            stepText = {
                markdown: `${rootPath}/waiting.md`
            }
            break;
        case 2:
        case 3:
        case 8:
            activeStep = 2;
            stepText = {
                markdown: `${rootPath}/approved.md`
            }
            break;
        case 4:
            activeStep = 3;
            stepText = {
                markdown: `${rootPath}/picked.md`
            }
            break;
        case 5:
            activeStep = 4;
            stepText = {
                markdown: `${rootPath}/complete.md`
            }
            break;
        case 6:
            activeStep = 5;
            stepText = {
                markdown: `${rootPath}/delivered.md`
            }
            break;
        default:
            console.error("unhandled order status");
            break;
    }

    const CustomConnector = withStyles({
        alternativeLabel: {
            top: 40,
        },
        active: {
            '& $line': {
                backgroundColor: theme.palette.primary.main,
                // background: 'linear-gradient(to right, ' + theme.palette.success.main + ',' + theme.palette.primary.main + ')',
            },
        },
        completed: {
            '& $line': {
                backgroundColor: theme.palette.success.main,
            },
        },
        line: {
            height: 2,
            border: 0,
            backgroundColor: theme.palette.greyThree.main,
            borderRadius: 1,
        },
    })(StepConnector);


    return (
        <div className={className}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<CustomConnector />}>
                {
                    steps.map(s => (
                        <Step key={s}>
                            <StepLabel StepIconComponent={CustomStepIcon}>{s}</StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
            <div className={classes.stepText}>
                <FingotiMarkdown path={stepText.markdown} />
            </div>
        </div>
    );

}