import { authUrl, cookieSettings } from './config';
import jwt_decode from 'jwt-decode';
import { Cookies } from 'react-cookie';
import { handleAppTokenResponse } from './response.service';

export var refreshLock = false;

const cookies = new Cookies();

const getAppToken = async (orgId) => {

    if (!refreshLock) {

        refreshLock = true;

        console.log("Getting app token...");

        let organisationId = orgId || await findAOCookie();
        
        if (organisationId) {
            return new Promise((resolve, reject) => {

                let requestOptions = {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ type: "Store", organisationId: organisationId })
                };

                return fetch(`${authUrl}/sso/token`, requestOptions)
                    .then(handleAppTokenResponse)
                    .then(result => {
                        delete result.success;
                        delete result.message;
                        console.log(result);
                        let tokenParts = jwt_decode(result.token);
                        tokenParts.token = result.token;
                        localStorage.setItem('FingotiStore', JSON.stringify(tokenParts));
                        cookies.set("ActiveOrganisation", organisationId, cookieSettings());
                        refreshLock = false;
                        return resolve(tokenParts)
                    })
                    .catch(error => {
                        console.log("Getting app token error");
                        console.log(error);
                        refreshLock = false;
                        return reject(error);
                    })
            })
        } else {
            return Promise.reject({ requireAuth: true, error: "Failed to get app token"});
        }
    } else {
        return Promise.reject("Refresh locked");
    }
}

const findAppToken = () => {
    console.log("Finding App token...");
    return new Promise((resolve, reject) => {
        const appToken = JSON.parse(localStorage.getItem('FingotiStore'));

        if (appToken) {
            return resolve(appToken);
        } else {
            return reject(null);
        }
    });
}

const findAOCookie = () => {
    return new Promise((resolve) => {
        let cookie = cookies.get("ActiveOrganisation");
        if (cookie) {
            return resolve(cookie);
        } else {
            findAppToken()
                .then(appToken => {
                    console.log("Cookie not found but LS exists, recreating AO cookie");
                    cookies.set("ActiveOrganisation", appToken.oid, cookieSettings());
                    console.log(`Org 1 id ${appToken.oid} `)
                    return resolve(appToken.oid);
                })
                .catch(() => {
                    console.log("Cookie not found and LS doesn't exist, unable to create cookie");
                    return resolve(false);
                })
        }
    })
}

const appLogout = () => {
    localStorage.removeItem('FingotiStore')
}

const authHeader = () => {
    const user = JSON.parse(localStorage.getItem('FingotiStore'));

    if (user && user.token) {
        return { Authorization: `Bearer ${user.token}` };
    } else {
        return { Authorization: `` };
    }
}


export const authService = {
    getAppToken,
    findAppToken,
    findAOCookie,
    authHeader,
    appLogout
}