import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { apiService } from "../services/api.service";
import { useProfileState } from "./ProfileContext";
import { useStoreDispatch } from "./StoreContext";

const StoreData = () => {
  const {
    organisation,
    addresses,
    loading: profileLoading,
  } = useProfileState();
  const dispatch = useStoreDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const setVATExempt = () => {
    let billingAddress = addresses.find(
      (addr) => addr.id === organisation.billingAddress
    );

    if (billingAddress.country === "GB") {
      dispatch({
        type: "set_vat_exempt",
        payload: {
          vatExempt: false,
        },
      });
    } else {
      dispatch({ type: "set_exvat", payload: true });
    }
  };

  useEffect(() => {
    if (organisation && addresses && !profileLoading) {
      if (organisation.isBusiness) {
        dispatch({ type: "set_exvat", payload: true });
      } else {
        dispatch({ type: "set_exvat", payload: false });
      }

      if (organisation.isPartner) {
        dispatch({ type: "set_pricing_tier", payload: 1 });
      } else {
        dispatch({ type: "set_pricing_tier", payload: 0 });
      }

      setVATExempt();
    } else if (!organisation && !profileLoading) {
      dispatch({
        type: "set_vat_exempt",
        payload: {
          vatExempt: false,
        },
      });
    }
  }, [organisation, addresses, profileLoading, dispatch]);

  const fillStoreContext = () => {
    dispatch({ type: "status", payload: { loading: true } });
    apiService
      .getData("/store/stock")
      .then((result) => {
        dispatch({ type: "add_stock", payload: { stock: result.stock } });
        dispatch({ type: "status", payload: { loading: false } });
      })
      .catch((error) => {
        dispatch({ type: "status", payload: { loading: false, error: true } });
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  return {
    fillStoreContext: fillStoreContext,
    setVATExempt: setVATExempt,
  };
};

export default StoreData;
