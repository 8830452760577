import clsx from "clsx";
import { Button, ButtonGroup, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  group: {
    width: "min-content",
  },
  textField: {
    "& > div": {
      borderRadius: "0px !important",
      "& > input, & > fieldset": {
        textAlign: 'center',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        color: theme.palette.greyOne.main,
      },
    },
    width: "6rem",
    borderRadius: "0px !important",
  },
  textFieldTight: {
    "& > input": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  defaultLight: {
    backgroundColor: theme.palette.greyFour.main,
    color: "#000",
    "&:hover": {
      backgroundColor: theme.palette.greyThree.main,
    },
    fontSize: "1.75rem",
    lineHeight: 1,
  },
  primaryLight: {
    backgroundColor: theme.palette.primary.light,
    color: "#000",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    fontSize: "1.75rem",
    lineHeight: 1,
  },
}));

export const QuantitySelect = ({
  disabled = false,
  quantity,
  setQuantity,
  increment,
  minValue,
  maxValue,
  className,
  tight = false,
}) => {
  const classes = useStyles();
  return (
    <ButtonGroup
      color="primary"
      disableElevation
      size="small"
      className={className}
    >
      <Button
        variant="contained"
        disabled={minValue === quantity || disabled}
        className={classes.primaryLight}
        onClick={() => setQuantity(quantity - increment)}
      >
        -
      </Button>
      <TextField
        disabled
        classes={{
          root: classes.textField,
        }}
        InputProps={{
          classes: {
            root: tight && classes.textFieldTight,
          },
        }}
        value={quantity}
      />
      <Button
        variant="contained"
        disabled={maxValue === quantity || disabled}
        className={classes.primaryLight}
        onClick={() => setQuantity(quantity + increment)}
      >
        +
      </Button>
    </ButtonGroup>
  );
};
